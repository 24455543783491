import { AnalyticsBrowser } from "@segment/analytics-next";
import { Segment as CommonSegmentInterface } from "@app/analytics/segment";
import setupAmpSRSegmentWrapper from "@amplitude/segment-session-replay-wrapper";
import { uuid } from "@app/util/uuid";

let segmentAnalytics: AnalyticsBrowser;
export const Segment = {
  ready: false,
  initialize(segmentApiKey, amplitudeApiKey) {
    segmentAnalytics = AnalyticsBrowser.load({
      writeKey: segmentApiKey,
    });
    void segmentAnalytics.ready(() => {
      this.ready = true;
      void setupAmpSRSegmentWrapper({
        segmentInstance: segmentAnalytics,
        amplitudeApiKey: amplitudeApiKey,
        sessionReplayOptions: {
          logLevel: 4,
          sampleRate: 1,
          debugMode: true,
        },
      }).catch((e) => {
        console.error("Amplitude Error", e);
      });
    });
  },
  trackEvent(eventName, eventProperties) {
    void segmentAnalytics.track(eventName, eventProperties);
  },
  setUser(userId) {
    void segmentAnalytics.identify(userId);
  },
  setUserProperties(properties: Record<string, unknown>) {
    void segmentAnalytics.identify(segmentAnalytics.ctx?.id, properties);
  },
  getSessionId() {
    /*
     When running `AnalyticsBrowser.load` segment determines the destinations configured for this source.
     Since Amplitude is configured, it will dynamically load javascript from their CDN to manage a session
     for amplitude and add it to each analytics request to Segment. In order for us to continue sessions
     on the backend, we need to pass this session id on our API requests. This is the simplest way to
     access the current session id. If this breaks and needs tweaking, review cdn.segment.com call to
     `settings` in the network tab and look into the `remotePlugins`. You can view the dynamic code there if necessary.
     */
    return Promise.resolve(
      localStorage.getItem("analytics_session_id") as string
    );
  },
  async getAnonymousId() {
    /*
     We want to be able to track anonymous users across sessions. Segment provides an anonymousId
     for this purpose. However, if segment is blocked from loading (pihole or otherwise) the promise to retrieve the
     Segment user is never resolved. To avoid our whole app hanging, we can instead go to the source of where it is
     stored manually. In the situation that it's not there, if we are "ready" meaning Segment is not blocked, we will
     wait up to 1 second for the promise to resolve. If it doesn't, we will generate a new anonymous Id and use it.
     Even if segment is always blocked this will create and store anonymous IDs now which is kinda nice.
     */
    const localStorageAnonymousId = localStorage.getItem("ajs_anonymous_id");
    if (localStorageAnonymousId) {
      return localStorageAnonymousId;
    }

    const cookieAnonymousId = getCookie("ajs_anonymous_id");
    if (cookieAnonymousId) {
      return cookieAnonymousId;
    }

    let anonymousId = null;
    if (this.ready) {
      anonymousId = Promise.race([
        segmentAnalytics.user().then((user) => user.anonymousId()),
        new Promise((resolve) => setTimeout(() => resolve(null), 1000)),
      ]);
    }
    if (anonymousId) {
      return anonymousId;
    }

    const newAnonymousId = uuid();
    void segmentAnalytics.setAnonymousId(newAnonymousId);
    localStorage.setItem("ajs_anonymous_id", newAnonymousId);
    return newAnonymousId;
  },
} as typeof CommonSegmentInterface;

function getCookie(cname: string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
