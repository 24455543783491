import { Segment as RealSegment } from "@app/analytics/segment";
import { Platform } from "expo-modules-core";
import { sentry } from "@app/util/sentry";
import { KeyedDebouncer } from "@app/analytics/KeyedDebouncer";
import {
  AnalyticsEvents,
  EventNames,
  NoEventProperties,
} from "@app/analytics/Events";
import { ENV } from "@app/config/env";

if (ENV.segmentDisableAnalytics) {
  console.info("Segment Analytics is disabled.");
}

const Segment = ENV.segmentDisableAnalytics
  ? ({
      // no op version of segment
      initialize: () => undefined,
      trackEvent: () => undefined,
      setUser: () => undefined,
      setUserProperties: () => undefined,
      getSessionId: async () => Promise.resolve(""),
      getAnonymousId: async () => Promise.resolve(""),
    } as typeof RealSegment)
  : RealSegment;

const trackEvent = (
  eventName: string,
  eventProperties?: Record<string, unknown>
) => {
  sentry.addBreadcrumb({
    type: "debug",
    level: "info",
    category: "Analytics.Event",
    message: eventName,
    data: eventProperties,
  });
  Segment.trackEvent(eventName, eventProperties);
};

const keyedDebouncer = new KeyedDebouncer(trackEvent, 5000, {
  // we want to track events immediately and ignore duplicate events for up to x seconds.
  // This helps ensure events are ordered correctly in Segment and do not affect funnels
  // that require specific order of events
  leading: true,
  trailing: false,
});

type AnalyticsInterface = {
  initialize: () => void;
  trackEvent: <T extends EventNames>(
    eventName: T,
    ...eventProperties: AnalyticsEvents[T] extends NoEventProperties
      ? []
      : [AnalyticsEvents[T]]
  ) => void;
  trackEventDebounced: <T extends EventNames>(
    debounceKey: string,
    eventName: T,
    ...eventProperties: AnalyticsEvents[T] extends NoEventProperties
      ? []
      : [AnalyticsEvents[T]]
  ) => void;
  setUser: (userId: string | null) => void;
  setUserProperties: (properties: Record<string, unknown>) => void;

  /**
   * Call is async because on mobile the session is maintained in the iOS/Android libraries.
   * Communication between JS and Native was done Asynchronously
   */
  getSessionId: () => Promise<string>;
  getAnonymousId: () => Promise<string | undefined | null>;
};

export const Analytics: AnalyticsInterface = {
  initialize() {
    const isWeb = Platform.OS === "web";
    if (isWeb) {
      window.addEventListener("beforeunload", () => {
        // listener must be added before Segment is initialized
        Segment.trackEvent("Leave App");
      });
    }

    const segmentWriteKey = isWeb
      ? ENV.segmentWebWriteKey
      : ENV.segmentNativeWriteKey;
    Segment.initialize(segmentWriteKey, ENV.amplitudeApiKey);
  },
  trackEvent,
  trackEventDebounced: (debounceKey, eventName, eventProperties?) => {
    keyedDebouncer.get(debounceKey + eventName)(eventName, eventProperties);
  },
  setUser: (userId) => {
    Segment.setUser(userId);
  },
  setUserProperties: (properties: Record<string, unknown>) => {
    Segment.setUserProperties(properties);
  },
  getSessionId: () => {
    return Segment.getSessionId();
  },
  getAnonymousId: () => {
    return Segment.getAnonymousId();
  },
};
